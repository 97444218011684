import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {excludeModal, triggerModal} from "../../../default/global/js/modal";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initInputMaskDate} from "../../../default/global/js/inputMaskDate";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {initScrollbars} from "../../../hofbraeu-global/js/scrollbar";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {setSrcVideos} from "../../../default/global/js/video";
import {initImageGallery} from "../../../hofbraeu-global/js/imageGallery";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {headerSetSticky} from "../../../hofbraeu-global/js/headerSetSticky";

window.addEventListener("load", (event) => {
    focusInputGroups();
    triggerModal();
    excludeModal();
    initJumpmarks();
    initPhoneCountryFields();
    initInputMaskDate();
    initSlideshows();
    setSrcVideos();
    initSlideshows();
    initImageGallery();
    initScrollbars();
    initSlidecontents();
    headerSetSticky();

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }
});
