export function headerSetSticky() {
    let header = document.querySelector('header');
    let stickyHeader = document.querySelector('.headerSticky');

    if (window.scrollY > 0) {
        header.classList.add('set-sticky');
    } else {
        header.classList.remove('set-sticky');
    }

    window.addEventListener("scroll", function () {
        if (window.scrollY > 0) {
            header.classList.add('set-sticky');
        } else {
            header.classList.remove('set-sticky');
        }
    });

    if (stickyHeader) {
        let headerHeight = header.getBoundingClientRect().height;

        if (window.scrollY > (headerHeight + 10)) {
            header.classList.add('showStickyHeader');
        } else {
            header.classList.remove('showStickyHeader');
        }

        window.addEventListener("scroll", function () {
            if (window.scrollY > (headerHeight + 10)) {
                header.classList.add('showStickyHeader');
            } else {
                header.classList.remove('showStickyHeader');
            }
        });
    }
}