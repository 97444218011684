import Swiper, {Autoplay, Navigation} from 'swiper';
import anime from "animejs";
import {openFullscreenGallery} from "../../default/global/js/fullscreengallery";

function resizeActiveSlide(swiperImageGallery,effInterval=400,first = false) {
    let currentSlideIndex = swiperImageGallery.activeIndex;
    let i = 0;

    for(let slide of swiperImageGallery.slides){
        let slideImgContainer = slide.querySelector('.imageGallery__img');
        let slideTextContainer = slide.querySelector('.imageGallery__text');
        if(slideImgContainer){
            slideImgContainer.style.width = null;
        }
        if(slideTextContainer){
            anime({
                targets: slideTextContainer,
                opacity: 0,
                easing: "linear",
                duration: effInterval
            });
        }
        slide.classList.remove('swiper-slide-scale');
    }
    if(first){
        effInterval = 0;
    }
    setTimeout(function () {
        for(let slide of swiperImageGallery.slides){
            let slideImgContainer = slide.querySelector('.imageGallery__img');
            let slideTextContainer = slide.querySelector('.imageGallery__text');
            if(i === currentSlideIndex && slide.classList.contains('swiper-slide-active')){
                slide.classList.add('swiper-slide-scale');
                if(slideImgContainer){
                    let slideImg = slideImgContainer.querySelector('img');
                    if(slideImg){
                        if(slideImg.complete){
                            slideImgContainer.style.width = slideImg.width +"px";
                        }
                        slideImg.onload = function () {
                            if(slideImg.complete){
                                slideImgContainer.style.width = slideImg.width +"px";
                            }
                        };
                    }
                }
                if(slideTextContainer){
                    anime({
                        targets: slideTextContainer,
                        opacity: 1,
                        easing: "linear",
                        duration: effInterval
                    });
                }
            }
            i++;
        }
    },effInterval);
}

export function initImageGallery(query = document.querySelectorAll('.imageGallery')) {
    let loops = {
        xs: false,
        sm: false,
        md: false,
        lg: false
    };

    let defaultOptions = {
        autoplay_interval: 3000,
        eff_interval: 1000,
        slidesPerBreakpoint: {
            xs: 1.5,
            sm: 3,
            md: 4,
            lg: 4
        }
    };

    for(const el of query){
        let options = {};

        if (el.getAttribute('data-options') !== "") {
            options = JSON.parse(el.getAttribute('data-options'));
        }

        options = {...defaultOptions,...options};
        let countSlides = el.querySelectorAll('.swiper-slide').length;

        if(countSlides >= options.slidesPerBreakpoint.lg){
            loops.lg = false;
        }

        if(countSlides >= options.slidesPerBreakpoint.md){
            loops.md = false;
        }

        if(countSlides >= options.slidesPerBreakpoint.sm){
            loops.sm = false;
        }

        if(countSlides >= options.slidesPerBreakpoint.xs){
            loops.xs = false;
        }

        Swiper.use([Autoplay,Navigation]);
        const swiperImageGallery = new Swiper(el, {
            speed: options.eff_interval,
            slideToClickedSlide: false,
            preventClicks: false,
            spaceBetween: 20,
            navigation: {
                nextEl: el.querySelector('.swiper-button-next'),
                prevEl: el.querySelector('.swiper-button-prev'),
            },
            autoplay: {
                delay: options.autoplay_interval,
            },
            breakpoints: {
                0: {
                    loop: loops.xs,
                    slidesPerView: options.slidesPerBreakpoint.xs,
                    spaceBetween: 10,
                    centeredSlides: false
                },
                768: {
                    loop: loops.sm,
                    slidesPerView: options.slidesPerBreakpoint.sm,
                    centeredSlides: false
                },
                1250: {
                    loop: loops.md,
                    slidesPerView: options.slidesPerBreakpoint.md,
                    centeredSlides: false
                },
                1540: {
                    loop: loops.lg,
                    slidesPerView: options.slidesPerBreakpoint.lg
                }
            },
            on: {
                init: function() {
                    resizeActiveSlide(this,options.eff_interval,true);
                },
                slideChange: function () {
                    resizeActiveSlide(this,options.eff_interval);
                }
            },
        });
        if(options.autoplay_interval === 0){
            swiperImageGallery.autoplay.stop();
        }
        el.addEventListener('mouseover',function(){
            swiperImageGallery.autoplay.stop();
        });

        el.addEventListener('mouseleave',function(){
            swiperImageGallery.autoplay.start();
        });
    }
}